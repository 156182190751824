<template>
    <div class="wrapper order-result">
        <div class="white-box">
            <div class="yellow-circle">
                <font-awesome-icon icon="fa-solid fa-burger" />
            </div>
            <h1>{{ $t('track-orders-title') }}</h1>

            <div v-if="orders && orders.length"  class="order-status-display">
                <order-status-display v-for="(order, key) in orders" :item="order" :key="'display-'+key"></order-status-display>
            </div>
            <div v-else>
                {{$t('no-active-orders')}}                

                <router-link to="/sector-selector">
				    <button class="gold-button new-order-button">
						{{$t('new-order')}}
					</button>
				</router-link>
            </div>
        </div>
    </div>
</template>
<style scoped>
    .new-order-button {
        display: grid;
        margin-top: 35px !important;
        margin-left: auto !important;
        margin-right: auto !important;
    }
</style>
<script>
import orderStatusDisplay from '../components/order-status-display.vue';

export default {
    components: { orderStatusDisplay },
    computed: {
        orders() {
            return this.$store.state.pendingOrders
        }
    },
    created() {
        this.$store.dispatch('loadPendingOrders');
        this.refreshPendingOrders();
        this.$store.commit('setSkyboxTitle', 'title-my-orders')
        this.$store.commit('setSkyboxText', '')
        this.$store.commit('setSkyboxLink', '')
        this.$store.commit('setSkyboxLinkText', '')
    },
    methods: {
        refreshPendingOrders() {
            setInterval(() => {
                this.$store.dispatch('loadPendingOrders');
            }, 10000);
        }
    }
}
</script>
