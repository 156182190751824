<template>
    <div class="order-status-item">
        <h4>{{ item.serial }} </h4>

        <div :class="'statuses ' + item.status">
            <div :class="getCssClass(status, key)" v-for="(status, key) in statuses" :key="'status-'+key">
                <div class="icon">
                    <font-awesome-icon icon="fa-solid fa-check" />
                </div>
                <div class="text">
                    {{ $t(status.label) }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "order-status-display",
    props: {
        item: {
            type: Object
        }
    },
    data() {
        return {
            statuses: [
                {
                    id: 'orderPayed',
                    label: 'order-status-orderPayed',
                    class: 'step1'
                },
                {
                    id: 'orderPreparing',
                    label: 'order-status-orderPreparing',
                    class: 'step2'
                },
                {
                    id: 'orderReady',
                    label: 'order-status-orderReady',
                    class: 'step3'
                },
            ]
        }
    },
    methods: {
        getCssClass(status, key) {
            let css = [];
            css.push('step' + (key + 1));
            css.push(status.id)

            return css;
        }
    }

}
</script>